<template>
  <div class="flex flex-col flex-1 card">
    <h1 class="text-2xl font-semibold text-gray-900">
      Wachtwoord wijzigen
    </h1>
    <form
      class="flex flex-col space-y-3 lg:space-y-4 items-start"
      action="#"
      method="PATCH"
      @submit.prevent.stop="submitPasswordChange"
    >
      <UIInput
        id="password"
        v-model="form.password"
        name="password"
        type="password"
        class="w-96"
        autocomplete="current-password"
        placeholder="Wachtwoord ..."
        label="Huidig Wachtwoord"
        required=""
      />
      <UIInput
        id="password"
        v-model="form.newPassword"
        name="password"
        type="password"
        class="w-96"
        autocomplete="new-password"
        placeholder="Wachtwoord ..."
        label="Nieuw Wachtwoord"
        required=""
      />
      <UIInput
        id="password"
        v-model="form.passwordConfirm"
        name="password"
        type="password"
        class="w-96"
        autocomplete="new-password"
        placeholder="Wachtwoord ..."
        label="Bevestig Nieuw Wachtwoord"
        required=""
      />

      <button type="submit" class="btn success">
        Opslaan
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'

import UIInput from '@/components/UI/Input/Input'

import useApi from '@/hooks/useApi'
import useAuthLogout from '@/hooks/useAuthLogout'

const api = useApi()
const store = useStore()

const form = ref({
  password: process.env.VUE_APP_AUTH_PASSWORD_DEVELOPMENT,
  newPassword: process.env.VUE_APP_AUTH_PASSWORD_DEVELOPMENT,
  passwordConfirm: process.env.VUE_APP_AUTH_PASSWORD_DEVELOPMENT,
  dashboard: true,
  email: store.state.profile.email,
})

const logout = useAuthLogout()

const submitPasswordChange = () => api('API_AUTH_PASSWORD_CHANGE', form).then(() => {
  alert('Wachtwoord aangepast, je word zo dadelijk uitgelogd.')
  logout()
})
</script>
