<template>
  <div class="flex flex-col flex-1 card">
    <h1 class="text-2xl font-semibold text-gray-900">
      Navigatie app voorkeur
    </h1>

    <form
      class="flex flex-col space-y-3 lg:space-y-4 items-start"
      action="#"
      method="PATCH"
      @submit.prevent.stop="handleSubmit"
    >
      <UISelectFixed
        v-model="form.navigatie_app"
        type="NAVIGATIE_APPS"
        label="Navigatie app"
        class="w-48"
      />

      <button type="submit" class="btn success" :disabled="!hasChanged">
        Opslaan
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'

import UISelectFixed from '@/components/UI/Select/Fixed.vue'

import useApi from '@/hooks/useApi'

const api = useApi()
const store = useStore()

const form = ref({
  navigatie_app: store.state.profile.navigatie_app || 'GOOGLE',
})

const hasChanged = ref(false)
watch(form, () => {
  hasChanged.value = true
}, { deep: true })

const handleSubmit = () => api('SETTING_EDIT', { property: 'navigatie_app', value: form.value.navigatie_app }).then(async () => {
  await store.dispatch('API_PROFILE_SYNC')
  alert('Navigatie app voorkeur opgeslagen.')
  hasChanged.value = false
})
</script>
